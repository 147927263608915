/* eslint-disable import/extensions */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('jquery/dist/jquery.min.js')
require('popper.js/dist/popper.min')
require('bootstrap/dist/js/bootstrap.min.js')

function scrollToAnchor(location) {
  if (location && location.hash) {
    const item = document.querySelector(`${location.hash}`)
    setTimeout(() => {
      if (item) {
        if (location.hash === '#job-offers') {
          item.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
        else {
          item.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }
    }, 400)
  }

  return true
}

exports.onRouteUpdate = ({ location }) => scrollToAnchor(location)
